import React, { useEffect, useState, useRef, useCallback } from 'react'
import ApiService from '../../ApiService'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import CountDown from './Countdown'
import CircularProgress from '@material-ui/core/CircularProgress'
import removeCSS from '../../util/RemoveCSS'
import './style.css'
import useUserStore from '../../hook/useUserStore'
import { subYears } from 'date-fns'
import { DateTime } from 'luxon'
import { parseAxiosError } from '../../util/parseHelper'
import Webcam from 'react-webcam'
import {isValidValue} from "../../util/common";

const RestStatus = {
  OK: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
}

const ErrorMsg = {
  Common: 'We’re having trouble registering this kit. Please check the kit ID and try again or contact customer care.',
  NotFound: `Sorry, the Kit ID you have entered is not valid. Please re-enter to try again`,
}

const MIN_KIT_ID_LENGTH = 5

const HelpModal = ({ hide, setHelpSent }) => {
  const [reason, setReason] = useState('')
  const [localImg, setLocalImg] = useState('')
  const [formPreparedData, setFormPreparedData] = useState()
  const webcamRef = useRef(null)
  const [hasCamera, setHasCamera] = useState(false)
  const [error, setError] = useState('')
  const [isRemoteCallInProgress, setIsRemoteCallInProgress] = useState(false)

  useEffect(() => {
    setError(null);
    navigator.mediaDevices
      .enumerateDevices()
      .then(devices => {
        const cameras = devices.filter(device => device.kind === 'videoinput')
        setHasCamera(cameras.length > 0)
      })
      .catch(error => {
        console.error('Error checking camera availability:', error)
        setHasCamera(false)

      })
  }, [])

  const capture = async () => {
    setError(null);
    if (!webcamRef || !webcamRef.current) {
      return
    }
    const imageSrc = webcamRef.current.getScreenshot()
    if (!imageSrc || imageSrc.length === 0) {
      setFormPreparedData(null)
      return
    }

    // Convert base64 image data to Blob
    const byteCharacters = atob(imageSrc.split(',')[1])
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], { type: 'image/jpeg' })

    let questionTxt = null;
    if(isValidValue(reason)) {
      questionTxt = reason;
    } else {
      questionTxt = document.getElementById('reason').value;
      setReason(questionTxt);
    }
    const formData = new FormData()
    formData.append('file', blob, 'kit.jpg')
    formData.append('questionType', 'REGISTER_KIT')
    formData.append('questionText', questionTxt)
    setFormPreparedData(formData)
    setLocalImg(imageSrc)
  }

  const onClick = async () => {
    setError(null);
    setHelpSent(false);
    let questionTxt = null;
    if(isValidValue(reason)) {
      questionTxt = reason;
    } else {
        questionTxt = document.getElementById('reason').value;
        setReason(questionTxt);
    }
    if(!isValidValue(questionTxt)){
        setError('Please input your question.');
        return;
    }
    try {
      setIsRemoteCallInProgress(true);
      if (hasCamera && formPreparedData) {
        // Clear the existing value (if any)
        formPreparedData.delete('questionText');
        formPreparedData.append('questionText', questionTxt);
        const response = await ApiService.postHelp(formPreparedData) //! TODO: API call
        setHelpSent(true)
      } else {
        const formData = new FormData()
        formData.append('questionType', 'REGISTER_KIT')
        formData.append('questionText', questionTxt || 'EMPTY')
        const response = await ApiService.postHelp(formData) //! TODO: API call
        setHelpSent(true)
      }
    } catch (error) {
      setError('Failed to send help request. Please try again.');
    } finally {
      hide()
      setIsRemoteCallInProgress(false);
    }
  }

  return (
    <div className="helpmodal">
      <div className="helpmodal-body">
        <div className="helpmodal-close" onClick={hide}>
          x
        </div>
        <div className="helpmodal-title">GET HELP</div>
        <div className="helpmodal-desc">Please enter below any issues you're having with registering kit and our team
          will help you
        </div>
        <br/>
        {error && <div className="registerKit-errors">{error}</div> }
        <br/>
        <input
            type="text"
            id="reason"
            className="login_inputs placeholderhelp"
            placeholder="type your issue here..."
            value={reason}
            onChange={e => setReason(e.target.value)}
            onInput={e => setReason(e.target.value)}
        />
        <br/>
        {!localImg && hasCamera &&
            <div className="helpmodal-desc">Please upload a photo of the KIT you are registering</div>}{' '}
        {localImg && <img alt="capture" src={localImg} className="helpmodal-preview"/>}
        {!localImg && hasCamera && (
            <div className="helpmodal-camera">
              <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  className="helpmodal-webcam"
                  videoConstraints={{
                    width: 1280,
                    height: 720,
                    facingMode: { exact: 'environment' },
                  }}
                  forceScreenshotSourceSize={true}
              />
            </div>
        )}
        {!localImg && hasCamera && (
            <div className="row registerKit-login-buttons">
              <button type="button" onClick={capture} className="btn socialbutton-customizable"
                      id="socialbutton-customizable">
                Capture
              </button>
            </div>
        )}
        {localImg && (
            <div className="row registerKit-login-buttons">
              <button disabled={!reason || isRemoteCallInProgress} type="button" onClick={onClick} className="btn socialbutton-customizable"
                      id="socialbutton-customizable">
                Submit
              </button>
            </div>
        )}
        {!hasCamera && (
            <div className="row registerKit-login-buttons">
              <button disabled={!reason || isRemoteCallInProgress} type="button" onClick={onClick} className="btn socialbutton-customizable"
                      id="socialbutton-customizable">
                Submit
              </button>
            </div>
        )}
      </div>
    </div>
  )
}

export default function RegisterKit() {
  const [kitNumber, setKitNumber] = useState('')
  const [testTakenDate, setTestTakenDate] = useState(false)
  const [symptoms, setSymptoms] = useState('')
  const [medications, setMedications] = useState('')
  const [page, setPage] = useState(1)
  const [error, setError] = useState('')
  const [dob, setDob] = useState(null)
  const [helpModal, setHelpModal] = useState(false)
  const patient = useUserStore(state => state.patient)
  const [helpSent, setHelpSent] = useState(false)
  const [confirmed, setConfirmed] = useState(false) // New state variable for the checkbox

  removeCSS()
  document.body.classList.add('body_portal')

  useEffect(() => {
    if (patient.dob && patient.dob.trim() !== "") {
      setDob(DateTime.fromISO(patient.dob).toJSDate());
    }
  }, [patient?.dob]);

  const handleSubmit = event => {
    setError('')
    const form = event.currentTarget
    //@ Check if the kit id is at-least 5 characters
    if (kitNumber.length < MIN_KIT_ID_LENGTH) {
      setError('We’re having trouble registering this kit. Please check the kit ID and try again or contact customer care.')
      return
    }
    if (!testTakenDate) {
      setError('Make sure to choose the date you took the sample.')
      return
    }
    if (!dob) {
      setError('Please enter and confirm birth date')
      return
    }
    setPage(3)
    const payload = {
      kitId: kitNumber ? kitNumber.toUpperCase() : '',
      testTakenDate: testTakenDate,
      dob: dob ? DateTime.fromJSDate(dob).toISODate() : '',
      symptoms: symptoms,
      medications: medications,
    }
    async function registerKitCaller() {
      try {
        await ApiService.postRegisterKit(payload)
        setKitNumber('')
        setPage(2)
      } catch (err) {
        setError(parseAxiosError(err)?.message ?? 'Request failed. Please try again')
        setPage(1)
      }
    }
    registerKitCaller().then(r => r)
  }

  return (
    <div className="form" id="registerKit-form">
      {helpModal && <HelpModal hide={() => setHelpModal(false)} setHelpSent={setHelpSent} />}
      {helpSent ? (
        <div className="help">We've received your request. Our team will get back to you shortly.</div>
      ) : (
        <div className="help" onClick={() => setHelpModal(true)}>
          Trouble registering your kit? Get help
        </div>
      )}
      <div className="form-body registerkit-body">
        <div className="panel-form" id="panel">
          <form id="kitRegistrationForm" className="needs-validation" noValidate>
            {/* <span className="registerkit_text">Enter the unique Kit ID located inside your kit.</span> */}
            <div className="registerKit-errors">{error}</div>
            {/*<span id="registerkit-notificationArea" className="registerKit-errors">{error}</span>*/}
            <div className="row w-100-mobile kitRegistrationForm">
              <div className="form-group register-kit-form">
              {page === 1 ? (
  <>
    <span className="registerkit_text mb">Register Your TBD Kit here</span>
    <span htmlFor="kitNumber" className="registerKit-label">
      Enter the unique Kit ID located on your kit.
    </span>
    <input
      type="text"
      id="kitNumberRegister"
      className={
        error === 'We’re having trouble registering this kit. Please check the kit ID and try again or contact customer care.'
          ? `registerKitFieldsNew red-outline`
          : `registerKitFieldsNew`
      }
      required="required"
      placeholder="Kit number"
      value={kitNumber}
      onInput={e => setKitNumber(e.target.value)}
    />
    <div
      style={{
        width: '100%',
        fontSize: '14px',
        textAlign: 'left',
        color: '#1F7F61',
        marginTop: '-20px',
        marginBottom: '20px',
      }}
    >
      Please enter your Kit ID in its entirety including any prefixes, i.e. "TBD12345" or "PPD12345" or "0182938485"
    </div>
    <span htmlFor="dob" className="registerKit-label">
      Day sample was taken?
    </span>
    <DatePicker
      className={error === 'Make sure to choose the date you took the sample.' ? `registerKitFieldsNew red-outline` : `registerKitFieldsNew`}
      placeholderText="Select"
      name="testTakenDate"
      id="testTakenDate"
      maxDate={new Date()}
      selected={testTakenDate ? moment(testTakenDate, 'YYYY-MM-DD').toDate() : false}
      onChange={date => setTestTakenDate(date)}
    />
    <span htmlFor="dob" className="registerKit-label">
      Do you have any symptoms?
    </span>
    <textarea
      type="text"
      className="registerKitFieldsNew registerKitFieldsNewTextArea"
      placeholder="List them here"
      value={symptoms}
      onInput={e => setSymptoms(e.target.value)}
    />
    <span htmlFor="dob" className="registerKit-label">
      Do you take any medications?
    </span>
    <textarea
      type="text"
      className="registerKitFieldsNew registerKitFieldsNewTextArea"
      placeholder="List them here"
      value={medications}
      onInput={e => setMedications(e.target.value)}
    />

    <span htmlFor="dob" className="registerKit-label">
      Please confirm your birthdate
    </span>
    <DatePicker
      className={error === 'Please confirm your birth date' ? `registerKitFieldsNew red-outline` : `registerKitFieldsNew`}
      placeholderText="Please confirm your birthdate"
      name="dob"
      value={dob ? dob : null}
      selected={dob ? dob : null}
      onChange={date => setDob(date)}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      maxDate={new Date()}
    />

    {/* New Checkbox for confirmation */}
    <div style={{ marginBottom: '20px' }}>
      <input
        type="checkbox"
        id="confirmation"
        checked={confirmed}
        onChange={(e) => setConfirmed(e.target.checked)}
      />
      <label htmlFor="confirmation" className="registerKit-label" style={{ fontWeight: 'normal' }}>
        I am the individual who submitted this sample. Note: TBD Health can only release results to the individual whose sample was submitted. If this is not you, please have that individual create an account and register the Kit. Failure to do this will result in significant delays in receiving results and/or the purging of the sample.
      </label>
    </div>

    <div id="submitButtonDiv" className="row registerKit-login-buttons">
      <button
        type="button"
        onClick={handleSubmit}
        disabled={!confirmed} // Button disabled until checkbox is checked
        className="btn socialbutton-customizable1"
        id="socialbutton-customizable1"
      >
        Register Kit
      </button>
    </div>
  </>
) : page === 2 ? (
  <CountDown className="count-down" />
) : (
  <>
    <div className="util_text">Please wait while we process your request...</div>
    <div className="flex just-cent">
      <CircularProgress className="loading-button-spinner" />
    </div>
  </>
)}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
